// import React from 'react';
// import { Card, CardContent, Typography } from '@mui/material';

// const KineticsCard = ({ yAxisLabel, max, min, fastCount, slowCount, fastPercentage, slowPercentage }) => {
//   return (
//     <Card className="kinetics-card">
//       <CardContent>
//         <Typography variant="h6" gutterBottom>
//           Kinetics Statistics for {yAxisLabel}
//         </Typography>
//         <Typography variant="body1">
//           <strong>Max Value:</strong> {max}
//         </Typography>
//         <Typography variant="body1">
//           <strong>Min Value:</strong> {min}
//         </Typography>
//         <Typography variant="body1">
//           <strong>Fast Phase Count:</strong> {fastCount}
//         </Typography>
//         <Typography variant="body1">
//           <strong>Slow Phase Count:</strong> {slowCount}
//         </Typography>
//         <Typography variant="body1">
//           <strong>Fast Phase Percent:</strong> {fastPercentage}%
//         </Typography>
//         <Typography variant="body1">
//           <strong>Slow Phase Percent:</strong> {slowPercentage}%
//         </Typography>
//       </CardContent>
//     </Card>
//   );
// };

// export default KineticsCard;
import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";

const DonutChart = ({ percentage }) => {
  const degree = (percentage / 100) * 360;

  return (
    <div
      style={{
        width: "120px", // Made slightly larger
        height: "120px", // Made slightly larger
        borderRadius: "50%",
        position: "relative",
        background: `conic-gradient(
          #4CAF50 0deg ${degree}deg,
          #f44336 ${degree}deg 360deg
        )`,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "84px", // 70% of outer circle
          height: "84px", // 70% of outer circle
          borderRadius: "50%",
          backgroundColor: "white",
        }}
      />
    </div>
  );
};

const KineticsCard = ({
  yAxisLabel,
  max,
  min,
  fastCount,
  slowCount,
  fastPercentage,
  slowPercentage,
}) => {
  return (
    <Card
      style={{ margin: "10px", backgroundColor: "#f5f5f5", minWidth: "400px" }}
    >
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ gap: "10px" }} // Added gap between elements
        >
          {/* Left side - Text content */}
          <Box flex="1" sx={{ minWidth: "100px" }}>
            {" "}
            {/* Added minimum width */}
            <Typography variant="h6" gutterBottom>
              {yAxisLabel}
            </Typography>
            <Typography variant="body2" sx={{ my: 0.5 }}>
              {" "}
              {/* Added vertical spacing */}
              <strong>Max:</strong> {max}
            </Typography>
            <Typography variant="body2" sx={{ my: 0.5 }}>
              <strong>Min:</strong> {min}
            </Typography>
            <Typography variant="body2" sx={{ my: 0.5 }}>
              <strong>Fast Count:</strong> {fastCount}
            </Typography>
            <Typography variant="body2" sx={{ my: 0.5 }}>
              <strong>Slow Count:</strong> {slowCount}
            </Typography>
            <Typography variant="body2" sx={{ my: 0.5 }}>
              <strong>Fast %:</strong> {fastPercentage}%
            </Typography>
            <Typography variant="body2" sx={{ my: 0.5 }}>
              <strong>Slow %:</strong> {slowPercentage}%
            </Typography>
          </Box>

          {/* Right side - Donut chart */}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{
              minWidth: "100px", // Ensure minimum width for chart area
              pl: 2, // Add left padding
            }}
          >
            <DonutChart percentage={parseFloat(fastPercentage) || 0} />
            {/* Legend */}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={2}
              sx={{ gap: "12px" }} // Added gap between legend items
            >
              <Box display="flex" alignItems="center">
                <Box
                  width={12}
                  height={12}
                  bgcolor="#4CAF50"
                  mr={1}
                  borderRadius="50%"
                />
                <Typography variant="caption">Fast</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Box
                  width={12}
                  height={12}
                  bgcolor="#f44336"
                  mr={1}
                  borderRadius="50%"
                />
                <Typography variant="caption">Slow</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default KineticsCard;
