import React, { useState, useEffect } from "react";
import { databases, account } from "../lib/appwrite";
import { Query } from "appwrite";
import { useAuth } from "../hooks/useAuth";
import {
  Container,
  Paper,
  Grid,
  TextField,
  Button,
  Typography,
  Alert,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

// Schema validation function
const validateDataAgainstSchema = (data) => {
  const allowedFields = [
    "userId",
    "roleID",
    "email",
    "Name",
    "organisation",
    "active",
    "height",
    "weight",
    "dob",
    "customBMR",
    "sex",
  ];

  const extraFields = Object.keys(data).filter(
    (key) => !allowedFields.includes(key)
  );

  if (extraFields.length > 0) {
    console.warn("Warning: Found fields not in schema:", extraFields);
  }

  const cleanData = {};
  allowedFields.forEach((field) => {
    if (data[field] !== undefined) {
      cleanData[field] = data[field];
    }
  });

  return cleanData;
};

const initialProfileState = {
  userId: "",
  roleID: "",
  email: "",
  Name: "",
  organisation: "",
  active: true,
  height: "",
  heightFt: "",
  heightIn: "",
  weight: "",
  dob: null,
  customBMR: "",
  sex: "",
};

const initialCalculations = {
  mifflin: 0,
  owen: 0,
  katch: 0,
  custom: 0,
  meanBMR: 0,
  bmi: 0,
};

const UserProfile = () => {
  const { user } = useAuth();
  const [profileData, setProfileData] = useState(initialProfileState);
  const [documentId, setDocumentId] = useState(null);
  const [units, setUnits] = useState("metric");
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [calculations, setCalculations] = useState(initialCalculations);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [passwordSuccess, setPasswordSuccess] = useState(null);
  const [passwordLoading, setPasswordLoading] = useState(false);

  useEffect(() => {
    if (user) {
      fetchUserProfile();
    }
  }, [user]);

  const fetchUserProfile = async () => {
    try {
      setLoading(true);
      setError(null);
      console.log("Fetching profile for user:", user.$id);

      const response = await databases.listDocuments(
        import.meta.env.VITE_APPWRITE_DATABASE_ID,
        import.meta.env.VITE_APPWRITE_USERS_COLLECTION_ID,
        [Query.equal("userId", user.$id)]
      );

      console.log("Profile response:", response);

      if (response.total > 0) {
        const userDocument = response.documents[0];
        setDocumentId(userDocument.$id);

        const formattedData = {
          ...initialProfileState,
          ...userDocument,
          height: userDocument.height?.toString() || "",
          heightFt: userDocument.heightFt?.toString() || "",
          heightIn: userDocument.heightIn?.toString() || "",
          weight: userDocument.weight?.toString() || "",
          customBMR: userDocument.customBMR?.toString() || "",
          dob: userDocument.dob ? new Date(userDocument.dob) : null,
        };

        console.log("Setting profile data:", formattedData);
        setProfileData(formattedData);

        if (formattedData.height && formattedData.weight && formattedData.sex) {
          calculateMetrics(
            parseFloat(formattedData.height),
            parseFloat(formattedData.weight),
            formattedData.sex
          );
        }
      } else {
        // Create new profile if none exists
        try {
          const newProfile = {
            ...initialProfileState,
            userId: user.$id,
            email: user.email,
            Name: user.name || "",
          };

          const createdDoc = await databases.createDocument(
            import.meta.env.VITE_APPWRITE_DATABASE_ID,
            import.meta.env.VITE_APPWRITE_USERS_COLLECTION_ID,
            "unique()",
            newProfile
          );

          console.log("Created new profile:", createdDoc);
          setDocumentId(createdDoc.$id);
          setProfileData(newProfile);
        } catch (createError) {
          console.error("Error creating profile:", createError);
          setError("Failed to create user profile.");
        }
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
      setError("Failed to load profile data.");
    } finally {
      setLoading(false);
    }
  };

  const calculateMetrics = (height, weight, sex) => {
    if (!height || !weight || !sex) return;

    let heightInCm;
    let weightInKg;

    if (units === "metric") {
      heightInCm = parseFloat(height);
      weightInKg = parseFloat(weight);
    } else {
      heightInCm =
        parseFloat(profileData.heightFt) * 30.48 +
        parseFloat(profileData.heightIn) * 2.54;
      weightInKg = parseFloat(weight) / 2.20462;
    }

    const heightInMeters = heightInCm / 100;
    const bmi = weightInKg / (heightInMeters * heightInMeters);

    const age = profileData.dob
      ? new Date().getFullYear() - profileData.dob.getFullYear()
      : 0;

    const mifflin =
      sex === "male"
        ? 10 * weightInKg + 6.25 * heightInCm - 5 * age + 5
        : 10 * weightInKg + 6.25 * heightInCm - 5 * age - 161;

    const owen =
      sex === "male" ? 879 + 10.2 * weightInKg : 795 + 7.18 * weightInKg;

    const katch = 370 + 21.6 * weightInKg;

    const bmrValues = [mifflin, owen, katch];
    if (profileData.customBMR)
      bmrValues.push(parseFloat(profileData.customBMR));

    const meanBMR =
      bmrValues.reduce((acc, val) => acc + val, 0) / bmrValues.length;

    setCalculations({
      bmi: Math.round(bmi * 10) / 10,
      mifflin: Math.round(mifflin),
      owen: Math.round(owen),
      katch: Math.round(katch),
      custom: profileData.customBMR ? parseFloat(profileData.customBMR) : 0,
      meanBMR: Math.round(meanBMR),
    });
  };

  const handleUnitChange = (event, newUnit) => {
    if (newUnit !== null) {
      setUnits(newUnit);
      calculateMetrics(profileData.height, profileData.weight, profileData.sex);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setProfileData((prev) => {
      const newData = {
        ...prev,
        [name]: value,
      };

      if (["height", "weight", "heightFt", "heightIn"].includes(name)) {
        calculateMetrics(
          parseFloat(newData.height) || 0,
          parseFloat(newData.weight) || 0,
          newData.sex
        );
      }

      return newData;
    });
  };

  const handlePasswordChange = async () => {
    if (
      !passwordData.currentPassword ||
      !passwordData.newPassword ||
      !passwordData.confirmPassword
    ) {
      setPasswordError("Please fill in all password fields");
      return;
    }

    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setPasswordError("New passwords do not match");
      return;
    }

    if (passwordData.newPassword.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      return;
    }

    try {
      setPasswordLoading(true);
      setPasswordError(null);

      await account.updatePassword(
        passwordData.newPassword,
        passwordData.currentPassword
      );

      setPasswordSuccess("Password updated successfully");
      setPasswordData({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } catch (error) {
      console.error("Error changing password:", error);
      setPasswordError(error.message);
    } finally {
      setPasswordLoading(false);
    }
  };

  const handleProfileSave = async () => {
    try {
      setLoading(true);
      setError(null);

      if (!documentId) {
        throw new Error("Document ID not found");
      }

      let heightInCm;
      if (units === "metric") {
        heightInCm = profileData.height ? parseFloat(profileData.height) : 0;
      } else {
        heightInCm =
          (profileData.heightFt
            ? parseFloat(profileData.heightFt) * 30.48
            : 0) +
          (profileData.heightIn ? parseFloat(profileData.heightIn) * 2.54 : 0);
      }

      let weightInKg;
      if (units === "metric") {
        weightInKg = profileData.weight ? parseFloat(profileData.weight) : 0;
      } else {
        weightInKg = profileData.weight
          ? parseFloat(profileData.weight) / 2.20462
          : 0;
      }

      const rawData = {
        userId: user.$id,
        roleID: profileData.roleID,
        email: profileData.email,
        Name: profileData.Name,
        organisation: profileData.organisation,
        active: true,
        height: heightInCm,
        weight: weightInKg,
        dob: profileData.dob?.toISOString(),
        customBMR: profileData.customBMR
          ? parseFloat(profileData.customBMR)
          : 0,
        sex: profileData.sex || "",
      };

      const dataToSave = validateDataAgainstSchema(rawData);
      console.log("Saving profile data:", dataToSave);

      const response = await databases.updateDocument(
        import.meta.env.VITE_APPWRITE_DATABASE_ID,
        import.meta.env.VITE_APPWRITE_USERS_COLLECTION_ID,
        documentId,
        dataToSave
      );

      console.log("Update response:", response);
      setSuccess("Profile updated successfully!");
      await fetchUserProfile();
    } catch (error) {
      console.error("Error updating profile:", error);
      setError(`Failed to update profile: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleSexChange = (event) => {
    const value = event.target.value;
    setProfileData((prev) => ({
      ...prev,
      sex: value,
    }));
    calculateMetrics(profileData.height, profileData.weight, value);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="h4" gutterBottom>
            User Profile
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {success}
            </Alert>
          )}

          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  name="Name"
                  value={profileData.Name || ""}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={profileData.email || ""}
                  disabled
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Organisation"
                  name="organisation"
                  value={profileData.organisation || ""}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label="Sex"
                  name="sex"
                  value={profileData.sex || ""}
                  onChange={handleSexChange}
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="">Select...</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <ToggleButtonGroup
                  value={units}
                  exclusive
                  onChange={handleUnitChange}
                >
                  <ToggleButton value="metric">Metric</ToggleButton>
                  <ToggleButton value="imperial">Imperial</ToggleButton>
                </ToggleButtonGroup>
              </Grid>

              {units === "metric" ? (
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Height (cm)"
                    name="height"
                    type="number"
                    value={profileData.height || ""}
                    onChange={handleInputChange}
                    inputProps={{ step: "0.1" }}
                  />
                </Grid>
              ) : (
                <>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Height (ft)"
                      name="heightFt"
                      type="number"
                      value={profileData.heightFt || ""}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Height (in)"
                      name="heightIn"
                      type="number"
                      value={profileData.heightIn || ""}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={`Weight (${units === "metric" ? "kg" : "lbs"})`}
                  name="weight"
                  type="number"
                  value={profileData.weight || ""}
                  onChange={handleInputChange}
                  inputProps={{ step: "0.1" }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Date of Birth"
                  value={profileData.dob}
                  onChange={(newValue) =>
                    setProfileData((prevData) => ({
                      ...prevData,
                      dob: newValue,
                    }))
                  }
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Custom BMR"
                  name="customBMR"
                  type="number"
                  value={profileData.customBMR || ""}
                  onChange={handleInputChange}
                  inputProps={{ step: "1" }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  BMR Calculations
                </Typography>
                <Typography>
                  Mifflin-St Jeor: {calculations.mifflin} kcal/day
                </Typography>
                <Typography>Owen: {calculations.owen} kcal/day</Typography>
                <Typography>
                  Katch-McArdle: {calculations.katch} kcal/day
                </Typography>
                {profileData.customBMR && (
                  <Typography>
                    Custom BMR: {calculations.custom} kcal/day
                  </Typography>
                )}
                <Typography>
                  Mean BMR: {calculations.meanBMR} kcal/day
                </Typography>
                <Typography>BMI: {calculations.bmi}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleProfileSave}
                  disabled={loading}
                  fullWidth
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Save Profile Changes"
                  )}
                </Button>
              </Grid>

              {/* Password Section */}
              <Grid item xs={12} sx={{ mt: 4 }}>
                <Paper elevation={2} sx={{ p: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Change Password
                  </Typography>
                  {passwordError && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                      {passwordError}
                    </Alert>
                  )}
                  {passwordSuccess && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                      {passwordSuccess}
                    </Alert>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Current Password"
                        type="password"
                        value={passwordData.currentPassword}
                        onChange={(e) =>
                          setPasswordData({
                            ...passwordData,
                            currentPassword: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="New Password"
                        type="password"
                        value={passwordData.newPassword}
                        onChange={(e) =>
                          setPasswordData({
                            ...passwordData,
                            newPassword: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Confirm New Password"
                        type="password"
                        value={passwordData.confirmPassword}
                        onChange={(e) =>
                          setPasswordData({
                            ...passwordData,
                            confirmPassword: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handlePasswordChange}
                        disabled={passwordLoading}
                        fullWidth
                      >
                        {passwordLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Update Password"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Container>
    </LocalizationProvider>
  );
};

export default UserProfile;
