import React, { useState, useEffect, useContext } from "react";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { useAuth } from "../hooks/useAuth"; // Add this import
import { DataContext } from "./context/DataContext";
// import { FitParser } from "fit-file-parser";
import FitParser from "fit-file-parser";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import TextField from "@mui/material/TextField";
import { MaterialReactTable } from "material-react-table";
import {
  Paper,
  TextField,
  Button,
  Stack,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Delete, Save } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { Bars } from "react-loader-spinner";

import "./HomePage.css";
import "./tableStyles.css";

const HomePage = () => {
  const { dataFrames, setDataFrames } = useContext(DataContext);
  const [isLodeVisible, setIsLodeVisible] = useState(false);
  const [selectedWorkbook, setSelectedWorkbook] = useState("");
  const [availableSheets, setAvailableSheets] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedDataFrame, setSelectedDataFrame] = useState("");
  const [previewData, setPreviewData] = useState([]);
  const [isCortexVisible, setIsCortexVisible] = useState(false);
  const [isCortexAbertayVisible, setIsCortexAbertayVisible] = useState(false); // Control Cortex checkbox visibility
  const [isCalibreVisible, setIsCalibreVisible] = useState(false);
  const [isCatapultVisible, setIsCatapultVisible] = useState(false);
  const [columnNames, setColumnNames] = useState([]);
  const { user: authUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  /// Resample to 1-second intervals
  const [showResampleControls, setShowResampleControls] = useState(false);
  const [resampleTimeField, setResampleTimeField] = useState("");
  const [resampleRate, setResampleRate] = useState(1);
  const [availableColumns, setAvailableColumns] = useState([]);
  /////
  const [checkboxes, setCheckboxes] = useState({
    polar: false,
    red10: false,
    cortexAbertay: false,
    cortexRT: false, // New checkbox for Cortex(RT)
    tcx: false, // New checkbox for TCX file
    calibre: false, // New checkbox for Calibre file
    fit: false, // New checkbox for FIT file
    lode: false,
    catapult: false,
    resample: false,
    newRed: false,
  });

  const handleCellChange = (rowIndex, columnKey, newValue) => {
    setPreviewData((prevData) =>
      prevData.map((row, index) =>
        index === rowIndex ? { ...row, [columnKey]: newValue } : row
      )
    );
  };

  const { user } = useAuth(); // Add this near the top with your other const declarations

  useEffect(() => {
    setIsCortexVisible(user && (user.role === "Admin" || user.role === "RT"));
    setIsCortexAbertayVisible(user && user.role === "Admin");
    setIsCalibreVisible(
      (user && user.role === "Admin") || user.role === "Invited"
    );
    setIsLodeVisible(user && user.role === "Admin");
    setIsCatapultVisible(user && user.role === "Admin");
  }, [user]);

  useEffect(() => {
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (fileExtension === "csv") {
        processFileWithCheckboxLogic(selectedFile, "csv");
      } else if (fileExtension === "xlsx" || fileExtension === "xls") {
        processFileWithCheckboxLogic(selectedFile, "excel");
      } else if (fileExtension === "tcx") {
        processFileWithCheckboxLogic(selectedFile, "tcx");
      } else if (fileExtension === "fit") {
        processFileWithCheckboxLogic(selectedFile, "fit");
      } else {
        console.error("Unsupported file type");
      }
    }
  }, [selectedFile, checkboxes]);

  useEffect(() => {
    if (selectedDataFrame && dataFrames[selectedDataFrame]) {
      const dataWithId = dataFrames[selectedDataFrame].map((row, index) => ({
        unique_id: index,
        ...row,
      }));
      setPreviewData(dataWithId.slice(0));
    } else {
      setPreviewData([]);
    }
  }, [selectedDataFrame, dataFrames]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setSelectedDataFrame(""); // Reset selected dataframe

      // If it's an Excel file, read the available sheets
      if (file.name.match(/\.(xlsx|xls)$/)) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          setAvailableSheets(workbook.SheetNames);
          setSelectedSheet(""); // Reset sheet selection
        };
        reader.readAsArrayBuffer(file);
      } else {
        setAvailableSheets([]);
        setSelectedSheet("");
      }
    }
  };

  // Update drop handler to handle sheets as well
  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
      setSelectedDataFrame(""); // Reset selected dataframe

      // If it's an Excel file, read the available sheets
      if (file.name.match(/\.(xlsx|xls)$/)) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          setAvailableSheets(workbook.SheetNames);
          setSelectedSheet(""); // Reset sheet selection
        };
        reader.readAsArrayBuffer(file);
      } else {
        setAvailableSheets([]);
        setSelectedSheet("");
      }
    }
  };

  // Handle cell editing with explicit state update
  const handleCellEdit = (rowIndex, columnId, newValue) => {
    setPreviewData((prevData) =>
      prevData.map((row, index) =>
        index === rowIndex ? { ...row, [columnId]: newValue } : row
      )
    );
  };

  const handleDeleteRow = (rowId) => {
    setPreviewData((prevData) =>
      prevData.filter((row) => row.unique_id !== rowId)
    );
  };

  const handleSaveChanges = () => {
    if (selectedDataFrame && dataFrames[selectedDataFrame]) {
      // Map the updated column names to the original data
      const updatedData = previewData.map((row) => {
        const updatedRow = {};
        columnNames.forEach((newName, index) => {
          const oldName = Object.keys(dataFrames[selectedDataFrame][0])[index];
          updatedRow[newName] = row[oldName];
        });
        return updatedRow;
      });

      // Update the dataFrames state with the updated data
      setDataFrames((prev) => ({
        ...prev,
        [selectedDataFrame]: updatedData,
      }));

      console.log(
        "Updated DataFrame with New Column Names and Data:",
        updatedData
      );
    }
  };

  const handleExportToCsv = () => {
    if (previewData.length === 0) return;

    const headers = Object.keys(previewData[0]);
    const csvRows = [
      headers.join(","), // header row
      ...previewData.map((row) =>
        headers.map((field) => JSON.stringify(row[field] ?? "")).join(",")
      ),
    ];
    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "exported_data.csv";
    a.click();
    URL.revokeObjectURL(url); // Clean up
  };

  useEffect(() => {
    // Initialize column names when previewData is available
    if (previewData.length > 0) {
      setColumnNames(Object.keys(previewData[0]));
    }
  }, [previewData]);

  const handleColumnNameChange = (index, newName) => {
    setColumnNames((prev) => {
      const updatedColumns = [...prev];
      updatedColumns[index] = newName;
      return updatedColumns;
    });
  };

  const columns = React.useMemo(
    () =>
      previewData.length > 0
        ? [
            ...Object.keys(previewData[0]).map((key, index) => ({
              accessorKey: key,
              header: (
                <TextField
                  defaultValue={key} // Editable column header
                  onBlur={(e) => {
                    const updatedColumnName = e.target.value.trim();
                    if (updatedColumnName && updatedColumnName !== key) {
                      handleColumnNameChange(index, updatedColumnName);
                    }
                  }}
                  variant="standard"
                  fullWidth
                  InputProps={{
                    disableUnderline: true, // Remove underline
                    sx: {
                      fontSize: "14px",
                      padding: "4px",
                    },
                  }}
                />
              ),
              Cell: ({ cell, row }) =>
                key === "unique_id" ? (
                  <span>{cell.getValue()}</span>
                ) : (
                  <TextField
                    defaultValue={cell.getValue()} // Editable cell
                    onBlur={(e) => {
                      const updatedValue = e.target.value;
                      handleCellEdit(row.index, key, updatedValue);
                    }}
                    fullWidth
                    variant="standard" // Use a minimal variant
                    InputProps={{
                      disableUnderline: true, // Remove underline
                      sx: {
                        padding: 0,
                        fontSize: "14px",
                        border: "none",
                        backgroundColor: "transparent",
                      },
                    }}
                    sx={{
                      padding: 0, // Remove padding for minimal look
                      "& .MuiOutlinedInput-root": {
                        padding: 0,
                        "& fieldset": {
                          border: "none", // Remove border around input
                        },
                      },
                    }}
                  />
                ),
            })),
            {
              id: "delete", // Unique ID for the delete column
              header: "", // Set header to empty to avoid showing "Actions"
              Cell: ({ row }) => (
                <Button
                  color="error"
                  onClick={() => handleDeleteRow(row.original.unique_id)}
                  startIcon={<Delete />}
                >
                  Delete
                </Button>
              ),
            },
          ]
        : [],
    [previewData]
  );

  const saveUpdatedColumnNames = () => {
    if (selectedDataFrame && dataFrames[selectedDataFrame]) {
      const updatedData = previewData.map((row) => {
        const updatedRow = {};
        columnNames.forEach((newName, index) => {
          const oldName = Object.keys(row)[index];
          updatedRow[newName] = row[oldName];
        });
        return updatedRow;
      });

      setDataFrames((prev) => ({
        ...prev,
        [selectedDataFrame]: updatedData,
      }));

      console.log("Updated DataFrame with New Column Names:", updatedData);
    }
  };

  //// Load file option
  const processFileWithCheckboxLogic = (file, fileType) => {
    if (!Object.values(checkboxes).some((checked) => checked)) {
      if (fileType === "csv") loadStandardCSV(file);
      else if (fileType === "excel") loadStandardExcel(file);
      else if (fileType === "tcx") loadStandardTCX(file);
    } else {
      if (checkboxes.calibre) loadCalibreFile(file);
      else if (checkboxes.polar) loadPolarFile(file);
      else if (checkboxes.red10) loadRed10File(file);
      else if (checkboxes.newRed) loadNewRedFile(file);
      else if (checkboxes.cortexAbertay) loadCortexAbertayFile(file, fileType);
      else if (checkboxes.cortexRT) loadCortexRTFile(file, fileType);
      else if (checkboxes.tcx) loadStandardTCX(file);
      else if (checkboxes.fit) loadFitFile(file);
      else if (checkboxes.catapult) loadCatapultFile(file);
      else if (checkboxes.lode && fileType === "excel")
        loadLodeFile(file); // Add Lode file processing
      else {
        if (fileType === "csv") loadStandardCSV(file);
        else if (fileType === "excel") loadStandardExcel(file);
        else if (fileType === "tcx") loadStandardTCX(file);
      }
    }
  };

  const loadCatapultFile = (file) => {
    console.log("Starting to load Catapult file...");
    setLoading(true); // Start loading
    setProgress(0); // Initialize progress to 0

    Papa.parse(file, {
      skipEmptyLines: true,
      complete: (result) => {
        setLoading(false); // End loading

        // Skip the first 6 lines of metadata
        const allRows = result.data;
        const data = allRows.slice(6);

        if (!data || data.length === 0) {
          console.error("No valid data found in the Catapult file.");
          return;
        }

        // Extract headers and format data correctly
        const headers = data[0];
        const rows = data.slice(1);

        const formattedData = rows.map((row) =>
          headers.reduce((acc, header, index) => {
            acc[header] = row[index];
            return acc;
          }, {})
        );

        console.log("Formatted Catapult data:", formattedData);

        // Check if 'Timestamp' column exists
        if (!formattedData[0]?.Timestamp) {
          console.error(
            "Catapult file is missing the 'Timestamp' column or the column names are not correctly parsed."
          );
          return;
        }

        // Store original Catapult data
        setDataFrames((prev) => ({ ...prev, Catapult_Data: formattedData }));
        setSelectedDataFrame("Catapult_Data");

        // Resample to 1-second intervals
        const resampledData = resampleToOneSecond(formattedData, "Timestamp");
        setDataFrames((prev) => ({ ...prev, Catapult_1s: resampledData }));

        console.log("Resampled Catapult data (1s):", resampledData);
      },
      error: (error) => {
        setLoading(false); // End loading in case of error
        console.error("Error parsing Catapult file:", error);
      },
    });
  };

  const loadStandardCSV = (file) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: (result) => {
        let data = result.data;

        // Ensure that data is an array of objects
        if (Array.isArray(data) && data.length > 0) {
          data = addSecondsColumn(data); // Add the "seconds" column to the data
        } else {
          console.error("No valid data found in CSV file.");
        }

        setDataFrames((prev) => ({ ...prev, Standard_Data: data }));
        setSelectedDataFrame("Standard_Data");
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
      },
    });
  };

  const loadStandardExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Format the data to be an array of objects
      const formattedData = jsonData.slice(1).map((row) =>
        jsonData[0].reduce((acc, colName, idx) => {
          acc[colName] = row[idx];
          return acc;
        }, {})
      );

      // Add the "seconds" column
      const dataWithSeconds = addSecondsColumn(formattedData);

      setDataFrames((prev) => ({ ...prev, Standard_Data: dataWithSeconds }));
      setSelectedDataFrame("Standard_Data");
    };
    reader.readAsArrayBuffer(file);
  };

  const loadPolarFile = (file) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      dynamicTyping: true,
      complete: (result) => {
        const headerRow = result.data[2];
        const dataRows = result.data.slice(3);

        const formattedData = dataRows.map((row, index) => ({
          sec: index,
          ...headerRow.reduce((acc, colName, idx) => {
            acc[colName] = row[idx];
            return acc;
          }, {}),
        }));

        setDataFrames((prev) => ({ ...prev, Polar_Heart: formattedData }));
        setSelectedDataFrame("Polar_Heart");
        setPreviewData(formattedData.slice(0, 10));
      },
      error: (error) => {
        console.error("Error parsing Polar file:", error);
      },
    });
  };
  // Add workbook selection handling
  const handleWorkbookChange = (event) => {
    setSelectedWorkbook(event.target.value);
    if (selectedFile && checkboxes.lode) {
      loadLodeFile(selectedFile);
    }
  };

  const loadRed10File = (file) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      dynamicTyping: true,
      complete: (result) => {
        const headerRow = result.data[38];
        const dataRows = result.data.slice(39);

        const red10Data = dataRows.map((row, index) => ({
          sec: index,
          ...headerRow.reduce((acc, colName, idx) => {
            acc[colName] = row[idx];
            return acc;
          }, {}),
        }));

        const red1sResampled = resampleRed10To1s(red10Data);

        setDataFrames((prev) => ({
          ...prev,
          Red_10: red10Data,
          Red_1s_resampled: red1sResampled,
        }));

        setSelectedDataFrame("Red_10");
        setPreviewData(red10Data.slice(0, 10));
      },
      error: (error) => {
        console.error("Error parsing Red 10 file:", error);
      },
    });
  };

  const resampleRed10To1s = (data) => {
    const roundedData = data.map((row) => ({
      ...row,
      Rounded_Timestamp: Math.round(row["Timestamp (seconds passed)"]),
    }));

    const groupedData = {};
    roundedData.forEach((row) => {
      const timestamp = row.Rounded_Timestamp;
      if (!groupedData[timestamp]) groupedData[timestamp] = [];
      groupedData[timestamp].push(row);
    });

    const resampledData = Object.keys(groupedData).map((timestamp, index) => {
      const group = groupedData[timestamp];
      const averagedRow = group.reduce((acc, row) => {
        Object.keys(row).forEach((key) => {
          if (key !== "Rounded_Timestamp") {
            acc[key] = (acc[key] || 0) + row[key] / group.length;
          }
        });
        return acc;
      }, {});

      return { Starting_Seconds: index, ...averagedRow };
    });

    return resampledData;
  };

  const loadCortexAbertayFile = (file, fileType) => {
    if (fileType === "excel") {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const dataRows = jsonData.slice(122);

        const rmrmapping = {
          0: "time",
          1: "phase",
          2: "marker",
          3: "vo2",
          4: "vo2kg",
          5: "vo2hr",
          6: "hr",
          7: "wr",
          8: "ve_vo2",
          9: "ve_vco2",
          10: "rer",
          11: "ve",
          12: "vt",
          13: "bf",
          14: "vco2",
        };

        const formattedData = dataRows.map((row) => {
          const rowData = {};
          Object.keys(rmrmapping).forEach((colIndex) => {
            rowData[rmrmapping[colIndex]] = row[colIndex];
          });
          return rowData;
        });

        const dataWithSeconds = addSecondsColumn(formattedData);

        setDataFrames((prev) => ({ ...prev, cortex: dataWithSeconds }));
        setSelectedDataFrame("cortex");
      };

      reader.onerror = (error) => {
        console.error("Error reading Cortex (Abertay) Excel file:", error);
      };

      reader.readAsArrayBuffer(file);
    } else {
      console.error("Unsupported file type for Cortex (Abertay) file");
    }
  };

  const loadCortexRTFile = (file, fileType) => {
    if (fileType === "excel") {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const dataRows = jsonData.slice(133); // Skip first 132 lines

        const columnNames = [
          "Times",
          "phase",
          "Marker",
          "VCO2",
          "VO2",
          "VO2kg",
          "VO2HR",
          "HR",
          "WR",
          "VEVO2",
          "VEVCO2",
          "RER",
          "VE",
          "VT",
          "BF",
          "EE",
          "EEWR",
          "EECHO",
          "EEFAT",
          "FAT",
          "CHO",
          "METS",
          "PERCO2",
          "PETO2",
          "Wkg",
          "v",
          "MHBLQ",
          "MHBRQ",
          "MHB3",
          "MHB4",
          "SMO2LQ",
          "SMO2RQ",
          "SMO3",
          "SMO4",
        ];

        const formattedData = dataRows.map((row) => {
          return columnNames.reduce((acc, colName, idx) => {
            // Only include columns that are not 'phase' or 'Marker'
            if (colName !== "phase" && colName !== "Marker") {
              acc[colName] = row[idx] || null;
            }
            return acc;
          }, {});
        });

        // Convert 'Times' column to seconds elapsed from the first row
        const initialTime = parseTimeToSeconds(formattedData[0]["Times"]);

        const dataWithAdjustedTimes = formattedData.map((row) => {
          const elapsedSeconds = parseTimeToSeconds(row["Times"]) - initialTime;
          return {
            ...row,
            Times: elapsedSeconds,
          };
        });

        setDataFrames((prev) => ({
          ...prev,
          Cortex_RT: dataWithAdjustedTimes,
        }));
        setSelectedDataFrame("Cortex_RT");
      };
      reader.onerror = (error) => {
        console.error("Error reading Cortex (RT) Excel file:", error);
      };
      reader.readAsArrayBuffer(file);
    } else {
      console.error("Unsupported file type for Cortex (RT) file");
    }
  };

  // Helper function to parse 'hh:mm:ss.sss' format to seconds
  const parseTimeToSeconds = (timeStr) => {
    const [hours, minutes, seconds] = timeStr.split(":").map(parseFloat);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const loadStandardTCX = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(e.target.result, "text/xml");
      const trackpoints = xmlDoc.getElementsByTagName("Trackpoint");
      const standard_tcx = [];

      let startTime = null;

      for (let i = 0; i < trackpoints.length; i++) {
        const time =
          trackpoints[i].getElementsByTagName("Time")[0]?.textContent;
        const heartRate = trackpoints[i]
          .getElementsByTagName("HeartRateBpm")[0]
          ?.getElementsByTagName("Value")[0]?.textContent;
        const position = trackpoints[i].getElementsByTagName("Position")[0];
        const latitude =
          position?.getElementsByTagName("LatitudeDegrees")[0]?.textContent;
        const longitude =
          position?.getElementsByTagName("LongitudeDegrees")[0]?.textContent;
        const power =
          trackpoints[i].getElementsByTagName("Watts")[0]?.textContent;
        const cadence =
          trackpoints[i].getElementsByTagName("Cadence")[0]?.textContent;

        // Parse the time string in a way that ensures consistent time zones
        const parsedTime = Date.parse(time);
        if (isNaN(parsedTime)) {
          console.error(`Invalid time format at index ${i}: ${time}`);
          continue;
        }

        if (startTime === null) {
          startTime = parsedTime; // Set start time as the first timestamp
        }

        const seconds = (parsedTime - startTime) / 1000; // Calculate seconds from start time

        standard_tcx.push({
          time: new Date(parsedTime).toISOString(),
          seconds: Math.round(seconds * 1000) / 1000, // Round to 3 decimal places
          heartRate: heartRate ? parseInt(heartRate) : null,
          latitude: latitude ? parseFloat(latitude) : null,
          longitude: longitude ? parseFloat(longitude) : null,
          power: power ? parseInt(power) : null,
          cadence: cadence ? parseInt(cadence) : null,
        });
      }

      setDataFrames((prev) => ({ ...prev, Standard_TCX: standard_tcx }));
      setSelectedDataFrame("Standard_TCX");
    };
    reader.readAsText(file);
  };

  //Fit File Loader
  const loadFitFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const buffer = e.target.result;
      const fitParser = new FitParser({
        force: true,
        speedUnit: "km/h",
        lengthUnit: "m",
        temperatureUnit: "celsius",
        elapsedRecordField: true,
        mode: "list",
      });

      fitParser.parse(buffer, (error, data) => {
        if (error) {
          console.error("Error parsing FIT file:", error);
          return;
        }

        const formattedRecords = data.records.map((record, index) => ({
          sec: index,
          timestamp: record.timestamp
            ? new Date(record.timestamp).toISOString()
            : null,
          speed: record.speed || null,
          distance: record.distance || null,
          power: record.power || null,
          heartRate: record.heart_rate || null,
        }));

        setDataFrames((prev) => ({ ...prev, FIT_File: formattedRecords }));
        setSelectedDataFrame("FIT_File");
        setPreviewData(formattedRecords.slice(0, 100));
      });
    };
    reader.readAsArrayBuffer(file);
  };

  // Calibre File Loader

  const loadCalibreFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Convert to JSON and skip the first 6 rows, then set the next row as headers
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      console.log("Raw JSON Data after Skipping 6 Lines:", jsonData);

      if (jsonData.length > 6) {
        const headers = jsonData[6]; // The 7th line as headers
        const dataRows = jsonData.slice(7); // Start data from the 8th line

        const formattedData = dataRows.map((row, index) => {
          const rowData = { sec: index };
          headers.forEach((header, idx) => {
            rowData[header] = row[idx];
          });
          return rowData;
        });

        console.log("Formatted Data:", formattedData); // Log the formatted data

        setDataFrames((prev) => ({ ...prev, Calibre_Data: formattedData }));
        setSelectedDataFrame("Calibre_Data");
        setPreviewData(formattedData.slice(0, 10));
      } else {
        console.error("Insufficient data in file after skipping rows.");
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const addSecondsColumn = (data) => {
    return data.map((row, index) => ({
      ...row,
      seconds: index, // This starts from 0 for each row
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes((prev) => ({
      polar: false,
      red10: false,
      cortexAbertay: false,
      cortexRT: false,
      tcx: false,
      calibre: false,
      newRed: false,
      [name]: checked,
    }));
    if (selectedFile) {
      setSelectedDataFrame(""); // Reset selected dataframe to trigger reprocessing
    }
  };

  const handleClearDataFrame = () => {
    if (selectedDataFrame) {
      setDataFrames((prev) => {
        const updatedDataFrames = { ...prev };
        delete updatedDataFrames[selectedDataFrame];
        return updatedDataFrames;
      });
      setSelectedDataFrame("");
      setPreviewData([]);
    }
  };

  //// Handle selecting the dataframe
  const handleDataFrameSelect = (event) => {
    const selectedFrame = event.target.value;
    setSelectedDataFrame(selectedFrame);

    if (
      selectedFrame &&
      dataFrames[selectedFrame] &&
      dataFrames[selectedFrame].length > 0
    ) {
      const columns = Object.keys(dataFrames[selectedFrame][0]);
      setAvailableColumns(columns);
      setResampleTimeField(columns[0] || "");
    } else {
      setAvailableColumns([]);
      setResampleTimeField("");
    }
  };

  /// Handle the resample of the selected dataframe
  // Add resampling handler

  const handleResample = () => {
    if (!selectedDataFrame || !resampleTimeField || !resampleRate) return;

    const originalData = dataFrames[selectedDataFrame];
    const resampledData = resampleDataFrame(
      originalData,
      resampleTimeField,
      resampleRate
    );

    const newDataFrameName = `${selectedDataFrame}_${resampleRate}s`;
    setDataFrames((prev) => ({
      ...prev,
      [newDataFrameName]: resampledData,
    }));

    setSelectedDataFrame(newDataFrameName);
  };

  // Update Lode file loading function
  const loadLodeFile = (file, sheetName) => {
    if (!sheetName) {
      console.error("No sheet selected");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const headers = jsonData[0];
      if (!headers.includes("Time")) {
        console.error("Selected sheet does not have a Time field.");
        return;
      }

      const formattedData = jsonData.slice(1).map((row, index) => {
        const rowData = {};
        headers.forEach((header, idx) => {
          rowData[header] = row[idx];
        });
        return {
          sec: index,
          ...rowData,
        };
      });

      setDataFrames((prev) => ({ ...prev, Lode_Bike: formattedData }));
      setSelectedDataFrame("Lode_Bike");
      setPreviewData(formattedData.slice(0, 10));
    };
    reader.readAsArrayBuffer(file);
  };

  const handleSheetSelect = (event) => {
    const selectedSheetName = event.target.value;
    console.log("Selected Sheet:", selectedSheetName);
    setSelectedSheet(selectedSheetName);

    if (selectedFile && checkboxes.lode) {
      console.log("Loading Lode file with selected sheet...");
      loadLodeFile(selectedFile, selectedSheetName)
        .then((loadedData) => {
          console.log("Loaded Data:", loadedData);
          setDataFrames((prev) => ({ ...prev, Lode_Bike: loadedData }));
          setSelectedDataFrame("Lode_Bike");
        })
        .catch((error) => {
          console.error("Error loading Lode file:", error);
        });
    }
  };

  const handleResampleChange = (event) => {
    const isChecked = event.target.checked;
    setCheckboxes((prev) => ({ ...prev, resample: isChecked }));

    if (isChecked && dataFrames["Lode_Bike"]) {
      const resampledData = resampleToOneSecond(
        dataFrames["Lode_Bike"],
        "Time"
      );
      setDataFrames((prev) => ({ ...prev, Lode_1s: resampledData }));
      setSelectedDataFrame("Lode_1s");
    }
  };

  const resampleCatapultToOneSecond = (data, timestampColumn = "Timestamp") => {
    if (!data || data.length === 0) {
      console.error("No data to resample");
      return [];
    }

    // Ensure the timestamp column is in seconds and numeric
    const parsedData = data.map((row) => ({
      ...row,
      [timestampColumn]: parseFloat(row[timestampColumn]), // Convert timestamp to float
    }));

    // Group data by whole seconds
    const groupedData = {};
    parsedData.forEach((row) => {
      const roundedTime = Math.floor(row[timestampColumn]); // Round down to the nearest second
      if (!groupedData[roundedTime]) groupedData[roundedTime] = [];
      groupedData[roundedTime].push(row);
    });

    // Aggregate data within each second
    const resampledData = Object.keys(groupedData).map((second) => {
      const group = groupedData[second];
      const aggregatedRow = group.reduce((acc, row) => {
        Object.keys(row).forEach((key) => {
          if (key === timestampColumn) {
            acc[timestampColumn] = parseInt(second, 10); // Use the rounded second
          } else if (typeof row[key] === "number") {
            acc[key] = (acc[key] || 0) + row[key]; // Sum numeric values
          } else if (!acc[key]) {
            acc[key] = row[key]; // For non-numeric fields, keep the first value
          }
        });
        return acc;
      }, {});

      // Calculate averages for numeric fields
      Object.keys(aggregatedRow).forEach((key) => {
        if (typeof aggregatedRow[key] === "number" && key !== timestampColumn) {
          aggregatedRow[key] = aggregatedRow[key] / group.length; // Average values
        }
      });

      return aggregatedRow;
    });

    return resampledData;
  };

  //// Lode Bike reampling function
  const resampleToOneSecond = (data, timeColumn) => {
    if (!data || data.length === 0) return [];

    // Convert all timestamps to seconds if they're not already
    const processedData = data.map((row) => {
      let timeInSeconds;
      if (typeof row[timeColumn] === "string") {
        // Handle HH:MM:SS format
        const [hours, minutes, seconds] = row[timeColumn]
          .split(":")
          .map(Number);
        timeInSeconds = hours * 3600 + minutes * 60 + seconds;
      } else {
        timeInSeconds = Number(row[timeColumn]);
      }
      return { ...row, [timeColumn]: timeInSeconds };
    });

    // Get the range of seconds
    const startTime = Math.floor(
      Math.min(...processedData.map((row) => row[timeColumn]))
    );
    const endTime = Math.ceil(
      Math.max(...processedData.map((row) => row[timeColumn]))
    );

    // Create an array for each second
    const resampledData = [];
    for (let second = startTime; second <= endTime; second++) {
      // Find all data points within this second
      const pointsInSecond = processedData.filter((row) => {
        const rowTime = row[timeColumn];
        return rowTime >= second && rowTime < second + 1;
      });

      if (pointsInSecond.length > 0) {
        // Create a new data point by averaging all values
        const averagedPoint = {};
        Object.keys(pointsInSecond[0]).forEach((key) => {
          if (key === timeColumn) {
            averagedPoint[key] = second;
          } else if (typeof pointsInSecond[0][key] === "number") {
            // Average numerical values
            averagedPoint[key] =
              pointsInSecond.reduce(
                (sum, point) => sum + (point[key] || 0),
                0
              ) / pointsInSecond.length;
          } else {
            // For non-numerical values, take the most frequent value
            const values = pointsInSecond.map((point) => point[key]);
            averagedPoint[key] = mode(values);
          }
        });
        resampledData.push(averagedPoint);
      }
    }

    return resampledData;
  };

  // Helper function to find the most frequent value in an array
  const mode = (arr) => {
    return arr.reduce((a, b) =>
      arr.filter((v) => v === a).length >= arr.filter((v) => v === b).length
        ? a
        : b
    );
  };

  //// dataframe resample function, to go from 10s to designated.

  // Add this function to detect time-related fields
  const detectTimeFields = (data) => {
    if (!data || data.length === 0) return [];

    const firstRow = data[0];
    console.log("First row of data:", firstRow);
    const timeFields = Object.keys(firstRow).filter(
      (key) =>
        key.toLowerCase().includes("time") ||
        key.toLowerCase().includes("sec") ||
        key.toLowerCase().includes("timestamp")
    );
    console.log("Detected time fields:", timeFields);
    return timeFields;
  };

  // Add generic resampling function
  const resampleDataFrame = (data, timeField, targetRate) => {
    if (!data || data.length === 0) return [];

    // Convert all timestamps to seconds if they're not already
    const processedData = data.map((row) => {
      let timeInSeconds;
      const timeValue = row[timeField];

      if (typeof timeValue === "string") {
        if (timeValue.includes(":")) {
          const [hours, minutes, seconds] = timeValue.split(":").map(Number);
          timeInSeconds = hours * 3600 + minutes * 60 + seconds;
        } else {
          timeInSeconds = parseFloat(timeValue);
        }
      } else {
        timeInSeconds = Number(timeValue);
      }
      return { ...row, [timeField]: timeInSeconds };
    });

    const startTime = Math.floor(
      Math.min(...processedData.map((row) => row[timeField]))
    );
    const endTime = Math.ceil(
      Math.max(...processedData.map((row) => row[timeField]))
    );

    // Create resampled array
    const resampledData = [];
    for (let time = startTime; time <= endTime; time += targetRate) {
      const pointsInInterval = processedData.filter((row) => {
        const rowTime = row[timeField];
        return rowTime >= time && rowTime < time + targetRate;
      });

      if (pointsInInterval.length > 0) {
        const averagedPoint = {};
        Object.keys(pointsInInterval[0]).forEach((key) => {
          if (key === timeField) {
            averagedPoint[key] = time;
          } else if (typeof pointsInInterval[0][key] === "number") {
            averagedPoint[key] =
              pointsInInterval.reduce(
                (sum, point) => sum + (point[key] || 0),
                0
              ) / pointsInInterval.length;
          } else {
            const values = pointsInInterval.map((point) => point[key]);
            averagedPoint[key] = mode(values);
          }
        });
        resampledData.push(averagedPoint);
      }
    }

    return resampledData;
  };

  const loadNewRedFile = (file) => {
    Papa.parse(file, {
      skipEmptyLines: "greedy",
      dynamicTyping: true,
      complete: (result) => {
        const timestampRowIndex = result.data.findIndex((row) =>
          row.some((cell) => cell === "Timestamp (seconds passed)")
        );

        if (timestampRowIndex === -1) {
          console.error("Could not find timestamp row");
          return;
        }

        // Get sensor IDs and headers
        const sensorIds = result.data[timestampRowIndex - 1];
        const headers = result.data[timestampRowIndex];

        // Process headers with sensor IDs
        let currentSensorId = "";
        const processedHeaders = headers.map((header, index) => {
          // Update current sensor ID if we find a new one
          if (sensorIds[index] && sensorIds[index].includes("Train.Red")) {
            currentSensorId = sensorIds[index].split(" ").pop();
          }
          // Skip Timestamp column
          if (header === "Timestamp (seconds passed)") {
            return header;
          }
          // Add sensor ID to column name if we have one
          return currentSensorId ? `${header}(${currentSensorId})` : header;
        });

        // Get data rows
        const dataRows = result.data.slice(timestampRowIndex + 2);

        // Format the data with processed headers
        const formattedData = dataRows.map((row, rowIndex) => {
          const rowData = {
            sec: rowIndex,
          };
          row.forEach((value, colIndex) => {
            if (processedHeaders[colIndex]) {
              rowData[processedHeaders[colIndex]] = value;
            }
          });
          return rowData;
        });

        // Create 1s resampled version
        const formattedData1s = resampleRed10To1s(formattedData);

        // Store both versions
        setDataFrames((prev) => ({
          ...prev,
          New_Red: formattedData,
          New_Red_1s: formattedData1s,
        }));

        setSelectedDataFrame("New_Red");
        setPreviewData(formattedData.slice(0, 10));
      },
      error: (error) => {
        console.error("Error parsing New Red file:", error);
      },
    });
  };

  /////////

  ////

  return (
    <div className="homepage-container">
      <div className="upload-section">
        <h3>Upload File for VO2Master / Polar / RED / Cortex / TCX</h3>
        {loading && (
          <div className="loading-bar">
            <p>Loading... {progress.toFixed(1)}%</p>
            <Bars color="#00BFFF" height={50} width={50} />
          </div>
        )}
        <div
          className={`file-upload-area ${isDragging ? "dragging" : ""}`}
          onDragOver={(e) => {
            e.preventDefault();
            setIsDragging(true);
          }}
          onDragLeave={() => setIsDragging(false)}
          onDrop={handleDrop}
          onClick={() => document.getElementById("fileInput").click()}
        >
          <input
            type="file"
            id="fileInput"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <p>
            {selectedFile
              ? selectedFile.name
              : "Drag and drop file here or click to browse"}
          </p>
          <small>
            Limit 200MB per file • XLSX, XLSB, CSV, C3D, JSON, FIT, EDF, TCX
          </small>
        </div>

        <div className="checkbox-section">
          <label>
            <input
              type="checkbox"
              name="polar"
              checked={checkboxes.polar}
              onChange={(e) =>
                setCheckboxes({ ...checkboxes, polar: e.target.checked })
              }
            />{" "}
            Polar File Format
          </label>
          <label>
            <input
              type="checkbox"
              name="red10"
              checked={checkboxes.red10}
              onChange={(e) =>
                setCheckboxes({ ...checkboxes, red10: e.target.checked })
              }
            />{" "}
            Red 10 Sensor Data
          </label>

          <label>
            <input
              type="checkbox"
              name="newRed"
              checked={checkboxes.newRed}
              onChange={(e) =>
                setCheckboxes({ ...checkboxes, newRed: e.target.checked })
              }
            />{" "}
            New Red Format
          </label>

          {isCortexVisible && (
            <>
              {isCortexAbertayVisible && (
                <label>
                  <input
                    type="checkbox"
                    name="cortexAbertay"
                    checked={checkboxes.cortexAbertay}
                    onChange={(e) =>
                      setCheckboxes({
                        ...checkboxes,
                        cortexAbertay: e.target.checked,
                      })
                    }
                  />{" "}
                  Cortex (Abertay)
                </label>
              )}
              <label>
                <input
                  type="checkbox"
                  name="cortexRT"
                  checked={checkboxes.cortexRT}
                  onChange={(e) =>
                    setCheckboxes({ ...checkboxes, cortexRT: e.target.checked })
                  }
                />{" "}
                Cortex (RT)
              </label>
            </>
          )}
          <label>
            <input
              type="checkbox"
              name="tcx"
              checked={checkboxes.tcx}
              onChange={(e) =>
                setCheckboxes({ ...checkboxes, tcx: e.target.checked })
              }
            />{" "}
            TCX File
          </label>
          {isCalibreVisible && (
            <label>
              <input
                type="checkbox"
                name="calibre"
                checked={checkboxes.calibre}
                onChange={(e) =>
                  setCheckboxes({ ...checkboxes, calibre: e.target.checked })
                }
              />{" "}
              Calibre Data
            </label>
          )}
          <label>
            <input
              type="checkbox"
              name="fit"
              checked={checkboxes.fit}
              onChange={(e) =>
                setCheckboxes({ ...checkboxes, fit: e.target.checked })
              }
            />{" "}
            FIT File
          </label>
          {isLodeVisible && (
            <div>
              <label>
                <input
                  type="checkbox"
                  name="lode"
                  checked={checkboxes.lode}
                  onChange={(e) =>
                    setCheckboxes({ ...checkboxes, lode: e.target.checked })
                  }
                />{" "}
                Lode Bike Data
              </label>
              {checkboxes.lode &&
                selectedFile &&
                availableSheets.length > 0 && (
                  <div
                    className="sheet-selection"
                    style={{ marginLeft: "20px", marginTop: "10px" }}
                  >
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type="checkbox"
                        name="resample"
                        checked={checkboxes.resample}
                        onChange={handleResampleChange}
                        style={{ marginRight: "10px" }}
                      />
                      <span>Resample to 1s</span>
                    </label>

                    <select
                      value={selectedSheet || ""} // Ensure it is explicitly set to an empty string if undefined
                      onChange={handleSheetSelect}
                      className="sheet-dropdown"
                      style={{
                        marginLeft: "10px",
                        padding: "5px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                      }}
                    >
                      <option value="">Select Sheet</option>
                      {availableSheets.map((sheetName, index) => (
                        <option key={index} value={sheetName}>
                          {sheetName}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
            </div>
          )}
          {/* Catapult Checkbox for Admin */}
          {user?.role === "Admin" && (
            <label>
              <input
                type="checkbox"
                name="catapult"
                checked={checkboxes.catapult}
                onChange={(e) =>
                  setCheckboxes({ ...checkboxes, catapult: e.target.checked })
                }
              />{" "}
              Catapult Data
            </label>
          )}
        </div>

        <div className="dataframe-selection">
          <label>Select a DataFrame:</label>
          <select
            value={selectedDataFrame || ""}
            onChange={(e) => setSelectedDataFrame(e.target.value)}
            className="dataframe-dropdown"
          >
            <option value="">Select a DataFrame</option>
            {Object.keys(dataFrames).map((name, index) => (
              <option key={index} value={name}>
                {name}
              </option>
            ))}
          </select>
          <button onClick={handleClearDataFrame} className="clear-button">
            Clear Selected DataFrame
          </button>
        </div>

        {previewData.length > 0 && (
          <div className="data-preview">
            <h4>Data Preview</h4>
            <Paper className="table-container">
              <MaterialReactTable
                columns={columns}
                data={previewData}
                enableEditing
                enableRowSelection
                getRowId={(row) => row.unique_id}
                renderTopToolbarCustomActions={() => (
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSaveChanges}
                      startIcon={<SaveIcon />}
                    >
                      Save Changes
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleExportToCsv}
                    >
                      Export to CSV
                    </Button>
                  </Stack>
                )}
              />
            </Paper>
            <Stack direction="row" spacing={2} justifyContent="flex-end" mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveChanges}
                startIcon={<SaveIcon />}
              >
                Save Changes
              </Button>
            </Stack>
          </div>
        )}
        {selectedDataFrame && (
          <div
            className="resampling-controls"
            style={{ marginTop: "20px", marginBottom: "100px" }}
          >
            <Button
              variant="outlined"
              onClick={() => setShowResampleControls(!showResampleControls)}
            >
              {showResampleControls
                ? "Hide Resampling Options"
                : "Show Resampling Options"}
            </Button>

            {showResampleControls &&
              dataFrames[selectedDataFrame] &&
              dataFrames[selectedDataFrame].length > 0 && (
                <Paper
                  className="resampling-options"
                  style={{ padding: "20px", marginTop: "10px" }}
                >
                  <Stack spacing={2}>
                    <div>
                      <label>Select Column for Time Values:</label>
                      <select
                        value={resampleTimeField}
                        onChange={(e) => setResampleTimeField(e.target.value)}
                        style={{ marginLeft: "10px", padding: "5px" }}
                      >
                        {Object.keys(dataFrames[selectedDataFrame][0]).map(
                          (column) => (
                            <option key={column} value={column}>
                              {column}
                            </option>
                          )
                        )}
                      </select>
                    </div>

                    <div>
                      <label>Sampling Rate (seconds):</label>
                      <input
                        type="number"
                        min="0.1"
                        step="0.1"
                        value={resampleRate}
                        onChange={(e) =>
                          setResampleRate(parseFloat(e.target.value))
                        }
                        style={{ marginLeft: "10px", padding: "5px" }}
                      />
                    </div>

                    <Button
                      variant="contained"
                      onClick={handleResample}
                      disabled={!resampleTimeField || !resampleRate}
                    >
                      Resample Data
                    </Button>
                  </Stack>
                </Paper>
              )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePage;
